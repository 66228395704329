<template>
  <div class="min-h-screen bg-center bg-cover bg-space flex items-center justify-center">
    <div class="text-white font-mono text-lg font-bold md:w-3/5 text-justify p-10 md:border-2 border-white rounded-md bg-black bg-opacity-30 flex flex-col items-center">
      <p>Progress Report</p>
      <br/>
      <p>
        This past year, I have spent my time fulfilling my civic engagement in two main ways : The first is encouraging health and fitness and the second is participating in school activities to raise awareness on current world problems.
        On the health and fitness side, I have been opening the school gym and helping people there for at least an hour every week. I have also been coaching three people, helping them get in shape and be healthier. I am helping them with their workouts in the gym by giving them workout plans and making sure they have proper form when doing the exercises. I am also helping them on their nutrition, what they need to be healthy and how to approach it to avoid eating disorders. All of this is contributing to the Good health and well-being UN objective.
        I have participated in the Fresque du climat and Fresque de la diversité activities which respectively contribute to the Climate action and Reduced inequalities UN objectives.
      </p>
      <br/>
      <br/>
      <video controls controlslist="nodownload nofullscreen noremoteplayback" disablePictureInPicture>
        <source src="PCE.mp4" type="video/mp4">
      </video>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CareerDevelopment',
}
</script>
